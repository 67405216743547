const ReactDOM = require('react-dom')

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const lazyLoad = () => {
    let lazyImages = [].slice.call(document.querySelectorAll('.lazy'));

    lazyImages.forEach(lazyImage => {
        let {top, bottom} = lazyImage.getBoundingClientRect();
        let isVisible = top <= window.innerHeight &&
            lazyImage.offsetParent && bottom >= 0;

        if (!isVisible) return;

        lazyImage.classList.remove('lazy');

        if (lazyImage.tagName === 'IMG') {
            lazyImage.src = lazyImage.dataset.src;
            return;
        }

        lazyImage.style.backgroundImage = `url(${lazyImage.dataset.src})`;
    });
};

document.lazyLoad = lazyLoad;

exports.replaceHydrateFunction = () => {
    return (element, container, callback) => {

        ReactDOM.render(element, container, callback);
    };
};

exports.onInitialClientRender = () => {
    let splashWrap = document.getElementById('home-splash-wrap');
    let index = +(sessionStorage.getItem(`splash_index`) || 0);

    if (splashWrap && splashWrap.classList.contains('anim') && index) {
        splashWrap.classList.remove('anim');
        splashWrap.classList.add('no-anim');
        splashWrap.firstChild.remove();
    }

    document.addEventListener('scroll', lazyLoad);
    document.addEventListener('click', () => setTimeout(lazyLoad, 100));
    window.addEventListener('resize', lazyLoad);
    window.addEventListener('orientationchange', lazyLoad);
};

exports.onRouteUpdate = () => lazyLoad();
