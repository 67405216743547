// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-work-post-post-js": () => import("../src/templates/work/post/post.js" /* webpackChunkName: "component---src-templates-work-post-post-js" */),
  "component---src-templates-work-index-js": () => import("../src/templates/work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */),
  "component---src-templates-news-post-index-js": () => import("../src/templates/news/post/index.js" /* webpackChunkName: "component---src-templates-news-post-index-js" */),
  "component---src-templates-news-index-js": () => import("../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-studio-collaborators-index-js": () => import("../src/templates/studio/collaborators/index.js" /* webpackChunkName: "component---src-templates-studio-collaborators-index-js" */),
  "component---src-pages-logo-js": () => import("../src/pages/logo.js" /* webpackChunkName: "component---src-pages-logo-js" */),
  "component---src-pages-news-index-js": () => import("../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-page-404-index-js": () => import("../src/pages/page_404/index.js" /* webpackChunkName: "component---src-pages-page-404-index-js" */),
  "component---src-pages-studio-contact-index-js": () => import("../src/pages/studio/contact/index.js" /* webpackChunkName: "component---src-pages-studio-contact-index-js" */),
  "component---src-pages-studio-index-js": () => import("../src/pages/studio/index.js" /* webpackChunkName: "component---src-pages-studio-index-js" */),
  "component---src-pages-studio-philosophy-index-js": () => import("../src/pages/studio/philosophy/index.js" /* webpackChunkName: "component---src-pages-studio-philosophy-index-js" */),
  "component---src-pages-studio-team-bios-index-js": () => import("../src/pages/studio/team-bios/index.js" /* webpackChunkName: "component---src-pages-studio-team-bios-index-js" */),
  "component---src-pages-work-index-js": () => import("../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */)
}

